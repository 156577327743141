import { Override } from "../types";
import {
  AssistType,
  DailyHabit as HabitDto,
  Event as EventDto,
  PlannerActionIntermediateResult,
  SubscriptionType,
  Task as TaskDto,
  TaskOrHabit as TaskOrHabitDto,
} from "./client";
import { dtoToEvent, Event } from "./Events";
import { dtoToHabit, Habit } from "./Habits";
import { dtoToTask, Task } from "./Tasks";
import { NotificationKeyStatus, TransformDomain } from "./types";

export type TaskOrHabit = Override<
  TaskOrHabitDto,
  {
    title: string | null;
    location?: string | null;
  }
>;

export const isTask = (taskOrHabit: TaskOrHabit): taskOrHabit is TaskDto => taskOrHabit.type === AssistType.TASK;
export const isHabit = (taskOrHabit: TaskOrHabit): taskOrHabit is HabitDto => !!taskOrHabit.type && taskOrHabit.type !== AssistType.TASK;

export type PlannerActionResult = {
  events: Event[];
  task?: Task;
  habit?: Habit;
};

export const fromDto = (dto: PlannerActionIntermediateResult): PlannerActionResult => {
  const result: PlannerActionResult = {
    events: dto.events.map((event: EventDto) => dtoToEvent(event)),
  };

  if (isTask(dto.taskOrHabit as TaskOrHabit)) {
    result.task = dtoToTask(dto.taskOrHabit as TaskDto);
  } else {
    result.habit = dtoToHabit(dto.taskOrHabit as HabitDto);
  }

  return result;
};

export class PlannerDomain extends TransformDomain<PlannerActionResult> {
  resource = "Planner";
  cacheKey = "planner";

  public deserialize = fromDto;

  handleIntermediateResult = (
    result: PlannerActionIntermediateResult,
    notificationKey: string
  ): PlannerActionIntermediateResult => {
    const subType = result.taskOrHabit.type === AssistType.TASK ? SubscriptionType.Task : SubscriptionType.DailyHabit;

    // Find active subscriptions and pipe data through.
    this.ws.subscriptions.forEach((value, key) => {
      if (key.indexOf(`${subType}_`) >= 0) {
        this.ws.pushMessage(result.taskOrHabit, subType, key);
      } else if (key.indexOf(`${SubscriptionType.Events}_`) >= 0) {
        this.ws.pushMessage(result.events, SubscriptionType.Events, key);
      }
    });

    this.updateNotificationKey(notificationKey, NotificationKeyStatus.Requested);
    return result;
  };

  setupNotification = (id: number): string => {
    const notificationKey = this.generateUid("planner", id);
    this.expectChange(notificationKey, id, {}, true);
    return notificationKey;
  };

  handleFailedAction = (reason: { status: number; response: unknown }, message: string, key: string) => {
    console.warn(message);
    this.clearExpectedChange(key, NotificationKeyStatus.Failed);
    throw reason;
  };

  /**
   * Task Actions
   */
  markTaskDone = this.manageErrors(
    this.deserializeResponse((id: number) => {
      const notificationKey = this.setupNotification(id);

      return this.api.planner
        .taskDone(id, { notificationKey })
        .then((response: PlannerActionIntermediateResult) => this.handleIntermediateResult(response, notificationKey))
        .catch((reason) =>
          this.handleFailedAction(reason, "Request failed: Could not mark task as done", notificationKey)
        );
    })
  );

  extendTask = this.manageErrors(
    this.deserializeResponse((id: number, minutes?: number) => {
      const notificationKey = this.setupNotification(id);

      return this.api.planner
        .extendTask(id, { minutes, notificationKey })
        .then((response: PlannerActionIntermediateResult) => this.handleIntermediateResult(response, notificationKey))
        .catch((reason) => this.handleFailedAction(reason, "Request failed: Could not extend task", notificationKey));
    })
  );

  logWork = this.manageErrors(
    this.deserializeResponse((id: number, minutes?: number) => {
      const notificationKey = this.setupNotification(id);

      return this.api.planner
        .logWork(id, { minutes, notificationKey })
        .then((response: PlannerActionIntermediateResult) => this.handleIntermediateResult(response, notificationKey))
        .catch((reason) =>
          this.handleFailedAction(reason, "Request failed: Could not log work for task", notificationKey)
        );
    })
  );

  restartTask = this.manageErrors(
    this.deserializeResponse((id: number) => {
      const notificationKey = this.setupNotification(id);

      return this.api.planner
        .restartTask(id, { notificationKey })
        .then((response: PlannerActionIntermediateResult) => this.handleIntermediateResult(response, notificationKey))
        .catch((reason) => this.handleFailedAction(reason, "Request failed: Could not restart task", notificationKey));
    })
  );

  snoozeTask = this.manageErrors(
    this.deserializeResponse((id: number, minutes?: number) => {
      const notificationKey = this.setupNotification(id);

      // TODO (IW): Remove default once not required on backend
      return this.api.planner
        .snoozeTask1(id, minutes || 240, { notificationKey })
        .then((response: PlannerActionIntermediateResult) => this.handleIntermediateResult(response, notificationKey))
        .catch((reason) => this.handleFailedAction(reason, "Request failed: Could not snooze task", notificationKey));
    })
  );

  startTaskNow = this.manageErrors(
    this.deserializeResponse((id: number) => {
      const notificationKey = this.setupNotification(id);

      return this.api.planner
        .startTaskNow(id, { notificationKey })
        .then((response: PlannerActionIntermediateResult) => this.handleIntermediateResult(response, notificationKey))
        .catch((reason) =>
          this.handleFailedAction(reason, "Request failed: Could not start task now", notificationKey)
        );
    })
  );

  stopTask = this.manageErrors(
    this.deserializeResponse((id: number) => {
      const notificationKey = this.setupNotification(id);

      return this.api.planner
        .stopTask(id, { notificationKey })
        .then((response: PlannerActionIntermediateResult) => this.handleIntermediateResult(response, notificationKey))
        .catch((reason) => this.handleFailedAction(reason, "Request failed: Could not stop task", notificationKey));
    })
  );

  /**
   * Habit Actions
   */

  extendHabit = this.manageErrors(
    this.deserializeResponse((id: number, minutes?: number) => {
      const notificationKey = this.setupNotification(id);

      return this.api.planner
        .extendHabit(id, { minutes, notificationKey })
        .then((response: PlannerActionIntermediateResult) => this.handleIntermediateResult(response, notificationKey))
        .catch((reason) => this.handleFailedAction(reason, "Request failed: Could not extend habit", notificationKey));
    })
  );

  restartHabit = this.manageErrors(
    this.deserializeResponse((id: number) => {
      const notificationKey = this.setupNotification(id);

      return this.api.planner
        .restartHabit(id, { notificationKey })
        .then((response: PlannerActionIntermediateResult) => this.handleIntermediateResult(response, notificationKey))
        .catch((reason) => this.handleFailedAction(reason, "Request failed: Could not restart habit", notificationKey));
    })
  );

  // TODO (SS): Remove default once not required on backend
  snoozeHabit = this.manageErrors(
    this.deserializeResponse((id: number, minutes?: number) => {
      const notificationKey = this.setupNotification(id);

      return this.api.planner
        .snoozeHabit(id, minutes || 60, { notificationKey })
        .then((response: PlannerActionIntermediateResult) => this.handleIntermediateResult(response, notificationKey))
        .catch((reason) => this.handleFailedAction(reason, "Request failed: Could not snooze habit", notificationKey));
    })
  );

  startHabitNow = this.manageErrors(
    this.deserializeResponse((id: number) => {
      const notificationKey = this.setupNotification(id);

      return this.api.planner
        .startHabitNow(id, { notificationKey })
        .then((response: PlannerActionIntermediateResult) => this.handleIntermediateResult(response, notificationKey))
        .catch((reason) =>
          this.handleFailedAction(reason, "Request failed: Could not start habit now", notificationKey)
        );
    })
  );

  stopHabit = this.manageErrors(
    this.deserializeResponse((id: number) => {
      const notificationKey = this.setupNotification(id);

      return this.api.planner
        .stopHabit(id)
        .then((response: PlannerActionIntermediateResult) => this.handleIntermediateResult(response, notificationKey))
        .catch((reason) => this.handleFailedAction(reason, "Request failed: Could not stop habit", notificationKey));
    })
  );
}
